import {
  IContainer,
  IGeneratePOItem,
  IPurchaseOrder,
  IPurchaseOrderItem,
  IPurchaseOrderList,
  TPurchaseSupplierList,
  TPurchaseWarehouseList,
} from "types/purchase";
import { Request } from "utils/request";

export const listPurchaseApi = (payload: any) => {
  return Request.call<{ data: IPurchaseOrderList }>({
    url: "/purchase-order/list",
    method: "PUT",
    data: payload,
  });
};

export const getTotalPOApi = (payload: { is_assist?: any }) => {
  return Request.call<{ data: { total: number } }>({
    url: "/purchase-order/total-value",
    method: "PUT",
    data: {
      ...payload,
    },
  });
};
export const createPurchaseApi = (payload: IPurchaseOrder) => {
  return Request.call<{ data: IPurchaseOrder }>({
    url: "/purchase-order/create",
    method: "PUT",
    data: payload,
  });
};

export const getDetailPurchaseApi = (payload: { id: any }) => {
  return Request.call<{ data: IPurchaseOrder }>({
    url: "/purchase-order/detail/" + payload.id,
    method: "GET",
  });
};

export const getListPurchaseSupplierApi = () => {
  return Request.call<{ data: TPurchaseSupplierList }>({
    url: "purchase-order/supplier",
    method: "GET",
  });
};

export const getListPurchaseWarehouseApi = () => {
  return Request.call<{ data: TPurchaseWarehouseList }>({
    url: "purchase-order/warehouse",
    method: "GET",
  });
};

export const cancelPurchaseOrderApi = (payload: { po_id: any }) => {
  return Request.call<{
    data: {
      so_id: any;
    };
  }>({
    url: "/purchase-order/cancel",
    method: "PUT",
    data: payload,
  });
};

export const deletePurchaseOrderApi = (payload: { po_id: any }) => {
  return Request.call<{
    data: {
      so_id: any;
    };
  }>({
    url: "/purchase-order/delete",
    method: "PUT",
    data: payload,
  });
};

export const updatePurchaseItemApi = (payload: {
  po_id: any;
  id?: any;
  item_id: any;
  quantity?: number;
  cost?: number;
  note?: string;
  is_delete?: boolean;
}) => {
  return Request.call<{ data: IPurchaseOrderItem }>({
    url: "/purchase-order/items/update",
    method: "PUT",
    data: payload,
  });
};

export const updatePurchaseVendorApi = (payload: {
  po_id: any;
  supplier_id: any;
}) => {
  return Request.call<{ data: any }>({
    url: "/purchase-order/supplier/update",
    method: "PUT",
    data: payload,
  });
};

export const updateCheckCostItemApi = (payload: {
  po_id: any;
  id?: any;
  item_id: any;
  check_cost?: number | null;
}) => {
  return Request.call<{ data: IPurchaseOrderItem }>({
    url: "/purchase-order/items/cost-check",
    method: "PUT",
    data: payload,
  });
};

export const listContainerApi = () => {
  return Request.call<{ data: IContainer[] }>({
    url: "/purchase-order/container",
    method: "GET",
  });
};

export const createContainerApi = (payload: any) => {
  return Request.call<{ data: any }>({
    url: "/purchase-order/container/create",
    method: "PUT",
    data: payload,
  });
};

export const updateContainerApi = (payload: any) => {
  return Request.call<{ data: any }>({
    url: "/purchase-order/container/update",
    method: "PUT",
    data: payload,
  });
};

export const shipContainerAPi = (payload: { id: any }) => {
  return Request.call<{ data: any }>({
    url: "/purchase-order/container/ship",
    method: "PUT",
    data: payload,
  });
};

export const getContainerDetailApi = (payload: { id: any }) => {
  return Request.call<{ data: IContainer }>({
    url: "/purchase-order/container/detail/" + payload.id,
    method: "GET",
  });
};

export const getPOGenerateItemsApi = () => {
  return Request.call<{ data: IGeneratePOItem[] }>({
    url: "/purchase-order/calculation-items/",
    method: "GET",
  });
};
