import { createSlice } from "@reduxjs/toolkit";
import { addListRemoteBuilderCase } from "redux-store/middlewares/builderUtils";
import { createCallApiAsyncAction } from "redux-store/middlewares/createAsyncAction";
import {
  actionQuotationApi,
  createQuotationApi,
  getDetailQuotationApi,
  getListQuotationApi,
  updateQuotationApi,
  updateQuotationItemApi,
} from "services/quotation.service";
import { IQuotation } from "types/quotation";

const TYPE = "QUOTATION";

export interface IQuotationState {
  list: IQuotation[];
  listTotalItems: number;
}

const initialState: IQuotationState = {
  list: [],
  listTotalItems: 0,
};

export const getListQuotation = createCallApiAsyncAction(
  TYPE + "/getListQuotation",
  getListQuotationApi
);

export const getDetailQuotation = createCallApiAsyncAction(
  TYPE + "/getDetailQuotation",
  getDetailQuotationApi
);

export const actionQuotation = createCallApiAsyncAction(
  TYPE + "/actionQuotation",
  actionQuotationApi
);

export const updateQuotation = createCallApiAsyncAction(
  TYPE + "/updateQuotation",
  updateQuotationApi
);

export const updateQuotationItem = createCallApiAsyncAction(
  TYPE + "/updateQuotationItem",
  updateQuotationItemApi
);

export const createQuotation = createCallApiAsyncAction(
  TYPE + "/createQuotation",
  createQuotationApi
);

const slice = createSlice({
  name: TYPE,
  initialState,
  extraReducers: (builder) => {
    addListRemoteBuilderCase({
      builder,
      fetchAction: getListQuotation,
      stateKey: "list",
    });
  },
  reducers: {},
});

export default slice.reducer;
