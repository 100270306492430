import {
  PrivateRoute,
  PublicRouteOnly
} from "components/PrivateRoute";
import PrivateLayout from "layout/PrivateLayout";
import PublicLayout from "layout/PublicLayout";
import CompanyVerifyPage from "pages/company-verify";

import React from 'react';
import { createBrowserRouter } from "react-router-dom";
import ROUTERS, { AUSABACO_ROUTERS, ausabacoScreensEnum, screensEnum } from "routers/constants";

const LoginPage = React.lazy(() => import("pages/login"));
const ProductDetailPage = React.lazy(() => import("pages/product/detail"));
const ProductListPage = React.lazy(() => import("pages/product/list"));
const ProductNewPage = React.lazy(() => import("pages/product/new"));
const PurchaseDetailPage = React.lazy(() => import("pages/purchase/detail"));
const IPurchaseOrderList = React.lazy(() => import("pages/purchase/list"));
const PurchaseNewPage = React.lazy(() => import("pages/purchase/new"));
const CustomerDetailPage = React.lazy(() => import("pages/customer-admin/detail"));
const CustomerListPage = React.lazy(() => import("pages/customer-admin/list"));
const CustomerNewPage = React.lazy(() => import("pages/customer-admin/new"));
const DashboardPage = React.lazy(() => import("pages/dashboard/dashboard"));
const EmployeeDetailPage = React.lazy(() => import("pages/employee/detail"));
const EmployeeListPage = React.lazy(() => import("pages/employee/list"));
const EmployeeNewPage = React.lazy(() => import("pages/employee/new"));
const ProfilePage = React.lazy(() => import("pages/employee/profile"));
const InventoryItemDetailPage = React.lazy(() => import("pages/inventory/detail"));
const InventoryGrid = React.lazy(() => import("pages/inventory/grid"));
const InventoryItemListPage = React.lazy(() => import("pages/inventory/list"));
const InvoiceDetailPage = React.lazy(() => import("pages/invoice/detail"));
const InvoiceListPage = React.lazy(() => import("pages/invoice/list"));
const ItemScanPage = React.lazy(() => import("pages/item-scan"));
const ManufacturingDetailPage = React.lazy(() => import("pages/manufacturing/detail"));
const ManufacturingListPage = React.lazy(() => import("pages/manufacturing/list"));
const PalletListPage = React.lazy(() => import("pages/pallet/list"));
const PrintsPage = React.lazy(() => import("pages/prints"));
const ProductImportPage = React.lazy(() => import("pages/product/import"));
const PromotionDetailPage = React.lazy(() => import("pages/promotion/detail"));
const PromotionListPage = React.lazy(() => import("pages/promotion/list"));
const PromotionNewPage = React.lazy(() => import("pages/promotion/new"));
const PalletPackingPage = React.lazy(() => import("pages/purchase/pallet-item"));
const ReceivePage = React.lazy(() => import("pages/purchase/receive"));
const QuickBookAuth = React.lazy(() => import("pages/quickbook"));
const ContainerListPage = React.lazy(() => import("pages/purchase/container-list"));
const ContainerDetailPage = React.lazy(() => import("pages/purchase/container-detail"));
const SalesOrderDetailPage = React.lazy(() => import("pages/sales-order/detail"));
const SalesOrderDetailPickPage = React.lazy(() => import("pages/sales-order/detail-pick"));
const SalesOrderListPage = React.lazy(() => import("pages/sales-order/list"));
const SalesOrderListBackorderPage = React.lazy(() => import("pages/sales-order/list-backorder"));
const SalesOrderPickListPage = React.lazy(() => import("pages/sales-order/list-pick"));
const SalesOrderNewPage = React.lazy(() => import("pages/sales-order/new"));
const SettingsPage = React.lazy(() => import("pages/settings/settings"));
const TaskListPage = React.lazy(() => import("pages/tasks/list"));
const UpsAuth = React.lazy(() => import("pages/ups"));
const WarehouseDetailPage = React.lazy(() => import("pages/warehouse/detail"));
const WarehouseListPage = React.lazy(() => import("pages/warehouse/list"));

const QuotationDetailPage = React.lazy(() => import("pages/quotation/detail"));
const QuotationNewPage = React.lazy(() => import("pages/quotation/new"));
const QuotationListPage = React.lazy(() => import("pages/quotation/list"));

const SalesReportListPage = React.lazy(() => import("pages/sales-report/list"));

const ContractPage = React.lazy(() => import("pages/ausabaco-pages/contract"));

const BoxListPage = React.lazy(() => import("pages/pallet/box"));
const BoxPackingPage = React.lazy(() => import("pages/purchase/box-item"));

export async function rootLoader(data: any) {
  console.log("rootloader", data);
  return {};
}

const router = createBrowserRouter([
  // public routes
  {
    element: <PublicLayout />,
    errorElement: <h1>You have no permission this page</h1>,
    children: [
      {
        path: '/',
        element: (
          <PublicRouteOnly Component={LoginPage} to={ROUTERS.PROFILE} />
        ),
      },
      {
        path: ROUTERS.LOGIN,
        element: (
          <PublicRouteOnly Component={LoginPage} to={ROUTERS.DASHBOARD} />
        ),
      },
      // PRINT
      {
        path: ROUTERS.PRINT,
        element: <PrintsPage />,
        id: screensEnum.PRINT,
      },
      {
        path: ROUTERS.COMPANY_VERIFY,
        element: <CompanyVerifyPage />,
        id: screensEnum.COMPANY_VERIFY,
      },
      {
        path: ROUTERS.NOT_FOUND,
        element: (
          <h3>
            You dont have permission this page -{" "}
            <a href="/" target="_self">
              Click here to back Home
            </a>
          </h3>
        ),
      },
    ],
  },
  // private routes
  {
    element: <PrivateRoute Component={PrivateLayout} />,
    errorElement: <h1>You have no permission this page</h1>,
    loader: rootLoader,
    children: [

      {
        path: ROUTERS.QUICKBOOKAUTH,
        element: <QuickBookAuth />,
        id: screensEnum.QUICKBOOKAUTH,
      },

      {
        path: ROUTERS.UPSAUTH,
        element: <UpsAuth />,
        id: screensEnum.UPSAUTH,
      },

      {
        path: ROUTERS.DASHBOARD,
        element: <DashboardPage />,
        id: screensEnum.DASHBOARD,
      },



      // PROFILE
      {
        path: ROUTERS.PROFILE,
        element: <ProfilePage />,
        id: screensEnum.PROFILE,
      },

      // PRODUCT
      {
        path: ROUTERS.PRODUCT,
        element: <ProductListPage />,
        id: screensEnum.PRODUCT,
      },
      {
        path: ROUTERS.PRODUCT_NEW,
        element: <ProductNewPage />,
        id: screensEnum.PRODUCT_NEW,
      },
      {
        path: ROUTERS.PRODUCT_DETAIL,
        element: <ProductDetailPage />,
        id: screensEnum.PRODUCT_DETAIL,
      },
      {
        path: ROUTERS.PRODUCT_IMPORT,
        element: <ProductImportPage />,
        id: screensEnum.PRODUCT_IMPORT,
      },

      // INVENTORY
      {
        path: ROUTERS.INVENTORY,
        element: <InventoryItemListPage />,
        id: screensEnum.INVENTORY,
      },
      {
        path: ROUTERS.INVENTORY_DETAIL,
        element: <InventoryItemDetailPage />,
        id: screensEnum.INVENTORY_DETAIL,
      },

      {
        path: ROUTERS.INVENTORY_GRID,
        element: <InventoryGrid />,
        id: screensEnum.INVENTORY_GRID,
      },


      // CUSTOMER
      {
        path: ROUTERS.CUSTOMER,
        element: <CustomerListPage />,
        id: screensEnum.CUSTOMER,
      },
      {
        path: ROUTERS.CUSTOMER_NEW,
        element: <CustomerNewPage />,
        id: screensEnum.CUSTOMER_NEW,
      },
      {
        path: ROUTERS.CUSTOMER_DETAIL,
        element: <CustomerDetailPage />,
        id: screensEnum.CUSTOMER_DETAIL,
      },

      // LEAD
      {
        path: ROUTERS.LEAD,
        element: <CustomerListPage isLead />,
        id: screensEnum.LEAD,
      },
      {
        path: ROUTERS.LEAD_NEW,
        element: <CustomerNewPage isLead />,
        id: screensEnum.LEAD_NEW,
      },
      {
        path: ROUTERS.LEAD_DETAIL,
        element: <CustomerDetailPage isLead />,
        id: screensEnum.LEAD_DETAIL,
      },

      // EMPLOYEE
      {
        path: ROUTERS.EMPLOYEE,
        element: <EmployeeListPage />,
        id: screensEnum.EMPLOYEE,
      },
      {
        path: ROUTERS.EMPLOYEE_NEW,
        element: <EmployeeNewPage />,
        id: screensEnum.EMPLOYEE_NEW,
      },
      {
        path: ROUTERS.EMPLOYEE_DETAIL,
        element: <EmployeeDetailPage />,
        id: screensEnum.EMPLOYEE_DETAIL,
      },

      // PALLET
      {
        path: ROUTERS.PALLET,
        element: <PalletListPage />,
        id: screensEnum.PALLET,
      },

      {
        path: ROUTERS.BOX,
        element: <BoxListPage />,
        id: screensEnum.BOX,
      },

      // RM Serial
      // {
      //   path: ROUTERS.RM_SERIAL,
      //   element: <RmSerialPage />,
      //   id: screensEnum.RM_SERIAL,
      // },

      // MANUFACTURING
      {
        path: ROUTERS.MANUFACTURING,
        element: <ManufacturingListPage />,
        id: screensEnum.MANUFACTURING,
      },
      {
        path: ROUTERS.MANUFACTURING_DETAIL,
        element: <ManufacturingDetailPage />,
        id: screensEnum.MANUFACTURING_DETAIL,
      },

      // PURCHASE
      {
        path: ROUTERS.PURCHASE,
        element: <IPurchaseOrderList key={0} />,
        id: screensEnum.PURCHASE,
      },
      {
        path: ROUTERS.PURCHASE_NEW,
        element: <PurchaseNewPage key={0} />,
        id: screensEnum.PURCHASE_NEW,
      },
      {
        path: ROUTERS.PURCHASE_DETAIL,
        element: <PurchaseDetailPage key={0} />,
        id: screensEnum.PURCHASE_DETAIL,
      },

      // ASSISTED PURCHASE
      {
        path: ROUTERS.ASSISTED_PURCHASE,
        element: <IPurchaseOrderList isAssisted key={1} />,
        id: screensEnum.ASSISTED_PURCHASE,
      },
      {
        path: ROUTERS.ASSISTED_PURCHASE_NEW,
        element: <PurchaseNewPage isAssisted key={1} />,
        id: screensEnum.ASSISTED_PURCHASE_NEW,
      },
      {
        path: ROUTERS.ASSISTED_PURCHASE_DETAIL,
        element: <PurchaseDetailPage isAssisted key={1} />,
        id: screensEnum.ASSISTED_PURCHASE_DETAIL,
      },

      // RECEIVE
      {
        path: ROUTERS.RECEIVE,
        element: <ReceivePage />,
        id: screensEnum.RECEIVE,
      },

      // RECEIVE
      {
        path: ROUTERS.ITEM_SCAN,
        element: <ItemScanPage />,
        id: screensEnum.ITEM_SCAN,
      },


      {
        path: ROUTERS.BOX_PACKING,
        element: <BoxPackingPage />,
        id: screensEnum.BOX_PACKING,
      },

      {
        path: ROUTERS.PALLET_PACKING,
        element: <PalletPackingPage />,
        id: screensEnum.PALLET_PACKING,
      },

      // SALES ORDER
      {
        path: ROUTERS.SALES_ORDER,
        element: <SalesOrderListPage />,
        id: screensEnum.SALES_ORDER,
      },
      {
        path: ROUTERS.SALES_ORDER_BACKORDER,
        element: <SalesOrderListBackorderPage />,
        id: screensEnum.SALES_ORDER_BACKORDER,
      },
      {
        path: ROUTERS.SALES_ORDER_PICK,
        element: <SalesOrderPickListPage />,
        id: screensEnum.SALES_ORDER_PICK,
      },
      {
        path: ROUTERS.SALES_ORDER_NEW,
        element: <SalesOrderNewPage />,
        id: screensEnum.SALES_ORDER_NEW,
      },
      {
        path: ROUTERS.SALES_ORDER_DETAIL,
        element: <SalesOrderDetailPage />,
        id: screensEnum.SALES_ORDER_DETAIL,
      },
      {
        path: ROUTERS.SALES_ORDER_BACKORDER_DETAIL,
        element: <SalesOrderDetailPage />,
        id: screensEnum.SALES_ORDER_BACKORDER_DETAIL,
      },
      {
        path: ROUTERS.SALES_ORDER_PICK_DETAIL,
        element: <SalesOrderDetailPickPage />,
        id: screensEnum.SALES_ORDER_PICK_DETAIL,
      },

      {
        path: ROUTERS.CONTAINER,
        element: <ContainerListPage />,
        id: screensEnum.CONTAINER,
      },
      {
        path: ROUTERS.CONTAINER_DETAIL,
        element: <ContainerDetailPage />,
        id: screensEnum.CONTAINER_DETAIL,
      },

      // PROMOTION
      {
        path: ROUTERS.PROMOTION,
        element: <PromotionListPage />,
        id: screensEnum.PROMOTION,
      },
      {
        path: ROUTERS.PROMOTION_NEW,
        element: <PromotionNewPage />,
        id: screensEnum.PROMOTION_NEW,
      },
      {
        path: ROUTERS.PROMOTION_DETAIL,
        element: <PromotionDetailPage />,
        id: screensEnum.PROMOTION_DETAIL,
      },

      // QUOTATION
      {
        path: ROUTERS.QUOTATION,
        element: <QuotationListPage />,
        id: screensEnum.QUOTATION,
      },
      {
        path: ROUTERS.QUOTATION_NEW,
        element: <QuotationNewPage />,
        id: screensEnum.QUOTATION_NEW,
      },
      {
        path: ROUTERS.QUOTATION_DETAIL,
        element: <QuotationDetailPage />,
        id: screensEnum.QUOTATION_DETAIL,
      },

      // SALES REPORT
      {
        path: ROUTERS.SALES_REPORT,
        element: <SalesReportListPage />,
        id: screensEnum.SALES_REPORT,
      },

      // Return
      // {
      //   path: ROUTERS.RETURN,
      //   element: <ReturnListPage />,
      //   id: screensEnum.RETURN,
      // },
      // {
      //   path: ROUTERS.RETURN_NEW,
      //   element: <ReturnNewPage />,
      //   id: screensEnum.RETURN_NEW,
      // },
      // {
      //   path: ROUTERS.RETURN_DETAIL,
      //   element: <ReturnDetailPage />,
      //   id: screensEnum.RETURN_DETAIL,
      // },

      // Invoice
      {
        path: ROUTERS.INVOICE,
        element: <InvoiceListPage />,
        id: screensEnum.INVOICE,
      },
      {
        path: ROUTERS.INVOICE_DETAIL,
        element: <InvoiceDetailPage />,
        id: screensEnum.INVOICE_DETAIL,
      },

      // Accounting
      {
        path: ROUTERS.ACCOUNTING,
        element: <h1>Accounting order</h1>,
        id: screensEnum.ACCOUNTING,
      },
      // WAREHOUSE
      {
        path: ROUTERS.WAREHOUSE,
        element: <WarehouseListPage />,
        id: screensEnum.WAREHOUSE,
      },
      {
        path: ROUTERS.WAREHOUSE_DETAIL,
        element: <WarehouseDetailPage />,
        id: screensEnum.WAREHOUSE_DETAIL,
      },

      // SETTINGS
      {
        path: ROUTERS.SETTINGS,
        element: <SettingsPage />,
        id: screensEnum.SETTINGS,
      },

      // TASKS
      {
        path: ROUTERS.TASKS,
        element: <TaskListPage />,
        id: screensEnum.TASKS,
      },

      // Report
      {
        path: ROUTERS.REPORT,
        element: <h1>Report</h1>,
        id: screensEnum.REPORT,
      },
    ],
  },

  {
    element: <PrivateRoute Component={PrivateLayout} />,
    errorElement: <h1>You have no permission this page</h1>,
    loader: rootLoader,
    children: [
      {
        path: AUSABACO_ROUTERS.CONTRACT,
        element: <ContractPage />,
        id: ausabacoScreensEnum.CONTRACT,
      },
    ],
  },
]);

export default router;
