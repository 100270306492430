import { IQuotation, IQuotationItem } from "types/quotation";
import { Request } from "utils/request";

export const getListQuotationApi = (payload: any) => {
  return Request.call<{ data: IQuotation[] }>({
    url: "/quotation/list",
    method: "PUT",
    data: payload,
  });
};

export const getDetailQuotationApi = (payload: { id: any }) => {
  return Request.call<{ data: IQuotation }>({
    url: "/quotation/detail/" + payload.id,
    method: "GET",
  });
};

export const updateQuotationApi = (payload: {
  name?: number;
  note?: string;
  tax_percent?: number;
  shipping_cost?: number;
  id: any;
}) => {
  return Request.call<{ data: IQuotation }>({
    url: "/quotation/update/",
    method: "PUT",
    data: payload,
  });
};

export const actionQuotationApi = (payload: { id: string; action: string, is_cbm?: boolean }) => {
  return Request.call<{ data: IQuotation }>({
    url: "/quotation/action/",
    method: "PUT",
    data: payload,
  });
};

export const updateQuotationItemApi = (payload: {
  quo_id: any;
  id?: any;
  item_id: any;
  quantity?: number;
  cost?: number;
  discount?: number;
  percent?: number;
  note?: string;
  is_delete?: boolean;
}) => {
  return Request.call<{ data: IQuotationItem }>({
    url: "/quotation/items/update",
    method: "PUT",
    data: payload,
  });
};

export const createQuotationApi = (payload: IQuotation) => {
  return Request.call<{ data: IQuotation }>({
    url: "/quotation/create",
    method: "PUT",
    data: payload,
  });
};
